export class Lever {
  id: string
  name: string
  label: string
  pictoURL: string
  featured: boolean
  prio: number

  constructor(
    id: string,
    name: string,
    label: string,
    pictoURL: string,
    featured: boolean = false,
    prio: number = 0
  ) {
    this.id = id
    this.name = name
    this.label = label
    this.pictoURL = pictoURL
    this.featured = featured
    this.prio = prio
  }
}
