import FacetFilter from "meilisearch"
const _ = require("lodash")

export class FacetFilters {
  filters: object

  constructor(filters: object = {}) {
    this.filters = filters
  }

  add(name: string, value: string) {
    if (_.has(this.filters, name) && this.filters[name].indexOf(value) == -1) {
      this.filters[name].push(value)
    } else {
      this.filters[name] = [value]
    }
  }

  remove(name: string, value: string) {
    if (_.has(this.filters, name)) {
      const index = this.filters[name].indexOf(value)
      this.filters[name].splice(index, 1)

      if (this.filters[name].length === 0) {
        delete this.filters[name]
      }
    }
  }

  removeAll(name: string) {
    if (_.has(this.filters, name)) {
      delete this.filters[name]
    }
  }

  reset() {
    this.filters = {}
  }

  hasValueSelected(name: string): boolean {
    return _.has(this.filters, name) && this.filters[name].length > 0
  }

  getFirstSelected(name: string): string {
    return _.has(this.filters, name) ? this.filters[name][0] : null
  }

  isSelected(name: string, value: string): boolean {
    return _.has(this.filters, name) && this.filters[name].indexOf(value) != -1
  }

  isEmpty(): boolean {
    return _.keys(this.filters).length == 0
  }

  flatten(): FacetFilter[] {
    const ff: FacetFilter[] = []

    for (const prop in this.filters) {
      ff.push(this.filters[prop].map(v => `${prop}:${v}`))
    }

    return ff
  }
}
