import React from "react"
import { Link } from "gatsby"

const FooterLink = ({ href, title, external = true }) => {
  return (
    <>
      {external === true ? (
        <Link
          to={href}
          className="w-full sm:w-auto sm:mx-4  lg:mx-4 hover:underline"
        >
          {title}
        </Link>
      ) : (
        <a
          href={href}
          target={external ? "_blank" : "_self"}
          rel="noopener noreferrer"
          className="w-full sm:w-auto sm:mx-4 lg:mx-4 hover:underline"
        >
          {title}
        </a>
      )}
    </>
  )
}

export default FooterLink
