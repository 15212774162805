/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Navbar from "./Navbar"

import "./layout.css"
import FooterLink from "./FooterLink"
import SocialLink from "./SocialLink"
import { SearchContextProvider } from "./SearchContextProvider"
import rsFacebook from "../images/rs_facebook.svg"
import rsTwitter from "../images/rs_twitter.svg"
import rsLinkedin from "../images/rs_linkedin.svg"
import theShifters from "../images/the-shifters.svg"

const getSocialLinks = data => [
  {
    href: "https://www.linkedin.com/company/shiftyourjob/",
    title: "LinkedIn",
    icon: rsLinkedin,
  },
  {
    href: "https://twitter.com/Shiftyourjob",
    title: "Twitter",
    icon: rsTwitter,
  },
  {
    href: "https://www.facebook.com/Shift-Your-Job-105381117907490",
    title: "Facebook",
    icon: rsFacebook,
  },
]

const getFooterLinks = data => [
  {
    href: "/partenaires",
    title: "Partenaires",
    external: false,
  },
  {
    href: "/glossaire",
    title: "Glossaire",
    external: false,
  },
  {
    href: "/equipe",
    title: "L'équipe",
    external: false,
  },
  {
    href: "/presse",
    title: "Presse",
    external: false,
  },
  {
    href: "/contact",
    title: "Nous contacter",
    external: true,
  },
]

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          contactFormUrl
        }
      }
    }
  `)
  const footerLinks = getFooterLinks(data)
  const socialLinks = getSocialLinks(data)
  return (
    <div className="flex flex-col min-h-screen antialiased bg-beige">
      <Navbar />
      <main className={`flex-grow pt-20 font-serif`}>{children}</main>
      <footer className="text-xs text-primary text-center lg:text-left px-4 flex flex-col-reverse lg:flex-row lg:justify-between lg:border-t items-center pb-4 lg:pb-0">
        <div className="lg:flex">
          <div className=" mt-3 lg:mt-0 lg:mr-3 text-gray-700 md:flex-grow">
            ©SHIFT&nbsp;YOUR&nbsp;JOB&nbsp;
          </div>
          <Link
            to="/mentions-legales"
            className="text-gray-700 lg:flex-grow hover:underline"
          >
            Mentions&nbsp;légales
          </Link>
        </div>
        <div className="lg:flex justify-center flex-wrap lg:justify-end items-center w-full">
          <div className="flex justify-center flex-wrap text-center items-center ">
            {footerLinks.map((l, i) => (
              <FooterLink
                key={l.title}
                href={l.href}
                title={l.title}
                external={l.external}
              />
            ))}
          </div>
          <div className="flex justify-center flex-wrap text-center mt-3 lg:mt-0 ">
            {socialLinks.map((l, i) => (
              <SocialLink
                key={l.title}
                href={l.href}
                title={l.title}
                icon={l.icon}
              />
            ))}
          </div>
          <div className="flex flex flex-col lg:flex-row justify-center items-center flex-wrap text-center mt-3 md:mt-0 lg:border-l py-4 lg:ml-4 lg:pl-4">
            <span>Un projet porté par</span>
            <a
              href="https://theshifters.org/"
              target="_blank"
              className="pt-2 lg:pt-0 md:pl-4"
            >
              <img src={theShifters} alt="" />
            </a>
          </div>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  contentClassName: PropTypes.string,
}

export default Layout
