import { Category } from "./models/category"
import { Coordinates } from "./models/coordinates"
import { Organization } from "./models/organization"
import { getOrganizationFields } from "./meilisearch"

export * from "./models/coordinates"
export * from "./models/facetfilters"
export * from "./models/organization"
export * from "./models/category"
export * from "./models/lever"

const slug = require("slug")
const hash = require("hash-sum")
const util = require("util")

export type organizationType = {
  nodes: {
    id: string
    data: {
      Name: string
      Notes: string
      Type_de_Structure: string
      Effectif: string
      Categories: [
        {
          id: string
        }
      ]
      Logo: {
        localFiles: [
          {
            childImageSharp: {
              fluid: any
            }
            ext: string
            publicURL: string
          }
        ]
      }
    }
  }[]
}

export type allCategoriesType = {
  nodes: {
    fields: {
      id: string
      data: {
        Name: string
        Notes: string
        Levier: {
          id: string
          data: {
            Name: string
          }
        }
        ContributionType: string
        Parent: {
          id: string
        }
        NewOrgCount: number
      }
    }
  }[]
}

export type LeverType = {
  id: string
  data: {
    Name: string
    Label: {
      childMarkdownRemark: { html: string }
    }
    Prio: number
    Featured: boolean
    LeverPictoURL: string
    Picto: {
      localFiles: [
        {
          childImageSharp: {
            fluid: any
          }
          ext: string
          url: string
        }
      ]
    }
  }
}
export type allLeverType = { nodes: LeverType[] }

export type CartoFilesType = {
  id: string
  data: {
    Name: string
    Notes: {
      childMarkdownRemark: { html: string }
    }
    PDFURL: string
    PDF: {
      localFiles: [
        {
          ext: string
          url: string
        }
      ]
    }
  }
}
export type allCartoFilesType = { nodes: CartoFilesType[] }

export type DispatchFormType = {
  id: string
  data: {
    Name: string
    Form: string
    FormPictoURL: string
    Picto: {
      localFiles: [
        {
          childImageSharp: {
            fluid: any
          }
          ext: string
          url: string
        }
      ]
    }
    Prio: number
  }
}
export type allDispatchFormType = { nodes: DispatchFormType[] }

export type GlossaireType = {
  id: string
  data: {
    Name: string
    Notes: {
      childMarkdownRemark: { html: string }
    }
  }
}
export type allGlossaireType = { nodes: GlossaireType[] }

export type PartnerType = {
  id: string
  data: {
    Name: string
    Description: {
      childMarkdownRemark: { html: string }
    }
    URL: string
    PartnerPictoURL: string
    Picto: {
      localFiles: [
        {
          childImageSharp: {
            fluid: any
          }
          ext: string
          url: string
        }
      ]
    }
  }
}
export type allPartnerType = { nodes: PartnerType[] }

export type allOrganizationsType = {
  nodes: {
    id: string
    data: {
      Name: string
      Categories: [
        {
          id: string
          data: {
            Name: string
          }
        }
      ]
    }
  }[]
}

export type OneOrganizationType = {
  id: string
  data: {
    Name: string
    Linkedin: string
    Website: string
    Categories: [
      {
        id: string
        data: {
          Name: string
          Order: string
        }
      }
    ]
    ActivityDescription: {
      childMarkdownRemark: { html: string }
    }
    City: [
      {
        id: string
        data: {
          CityName: string
          GPSCoordinates: string
          DepartmentName: string
          RegionName: string
          Country: string
          Dpt2: string
        }
      }
    ]
    Headquarter: [
      {
        id: string
        data: {
          CityName: string
          GPSCoordinates: string
          DepartmentName: string
          RegionName: string
          Dpt2: string
        }
      }
    ]
    CreationYear: string
    StructureType: string
    Tags: string
    Lever: [LeverType]
    Workforce: string
    Trainings: [
      {
        id: string
        data: {
          Training: string
        }
      }
    ]
    Professions: [
      {
        id: string
        data: {
          Profession: string
        }
      }
    ]
  }
}

export class CartoFiles {
  id: string
  name: string
  notes: string
  PDFURL: string

  constructor(id: string, name: string, notes: string, PDFURL: string) {
    this.id = id
    this.name = name
    this.notes = notes
    this.PDFURL = PDFURL
  }
}

export class DispatchForm {
  id: string
  name: string
  pictoURL: string
  form: string
  prio: number

  constructor(
    id: string,
    name: string,
    pictoURL: string = "",
    form: string = "",
    prio = 0
  ) {
    this.id = id
    this.name = name
    this.pictoURL = pictoURL
    this.form = form
    this.prio = prio
  }

  get slug(): string {
    return `/contribuer/${slug(this.name)}`
  }
}

export class Glossaire {
  id: string
  name: string
  notes: string

  constructor(id: string, name: string, notes: string) {
    this.id = id
    this.name = name
    this.notes = notes
  }
}

export class Partner {
  id: string
  name: string
  description: string
  picto: string
  url: string

  constructor(
    id: string,
    name: string,
    description: string,
    picto: string,
    url: string
  ) {
    this.id = id
    this.name = name
    this.description = description
    this.picto = picto
    this.url = url
  }
}

export type MapOrganization = {
  id: string
  name: string
  fullCoordinates: Coordinates[]
  fullLocations: string[]
}

interface WalkCallbackType {
  (node: Category): void
}

function transformOrg(org) {
  let organization_fields: any = getOrganizationFields(org, {})
  organization_fields.structure = organization_fields.type
  organization_fields.effectif = organization_fields.workforce
  return new Organization(organization_fields)
}

// Build a tree based on flat Airtable data
export class Tree {
  root: Category
  flat: Array<any>

  constructor(basePath: string, flat: Array<any>, rootName: string) {
    this.flat = flat
      .filter(cat => cat.data.Order != null)
      .map(cat => {
        let o = cat.data.Order.slice(0, -1).split(".")
        cat.level = o.length
        cat.sort = parseInt(o.pop())
        return cat
      })

    this.root = new Category(
      null,
      "",
      rootName,
      "",
      "",
      "",
      "",
      "",
      "",
      null,
      0,
      0,
      false
    )
    this.root.children = this.build(this.root)

    // console.log(util.inspect(this.root, {showHidden: false, depth: null}));
    this.flat = null
  }

  private build(parent: Category): Array<Category> {
    // ceux qui commence pareil et level + 1 et sorted
    return this.flat
      .filter(
        item =>
          item.level === parent.level + 1 &&
          item.data.Order.startsWith(parent.id)
      )
      .sort((a, b) => a.sort - b.sort)
      .map(item => {
        let pictoURL = null
        if (item.data.CategoryPictoURL) {
          pictoURL = item.data.CategoryPictoURL
        }

        let organizations = []
        if (item.data.Organizations) {
          organizations = item.data.Organizations.map(org => transformOrg(org))
        }

        let node = new Category(
          parent,
          item.data.Order,
          item.data.Name,
          item.data.Description?.childMarkdownRemark.html,
          item.data.SEOTitle,
          item.data.SEODesc,
          item.data.SEOSearchDesc,
          item.data.Highlights?.childMarkdownRemark.html,
          item.data.ContributionType,
          pictoURL,
          item.data.NewOrgCount,
          item.level,
          item.data.HasDedicatedPage,
          item.id,
          organizations
        )

        node.children = this.build(node)

        return node
      })
  }

  walk(callback: WalkCallbackType) {
    this._walk(this.root, callback)
  }

  private _walk(node: Category, callback: WalkCallbackType) {
    for (let child of node.children) {
      // console.log(node.slug)
      callback(child)
      this._walk(child, callback)
    }
  }

  find(id: string): Category {
    return this._find(this.root, id)
  }

  private _find(node: Category, id: string): Category {
    if (node.id === id) {
      return node
    }

    for (let child of node.children) {
      let found = this._find(child, id)
      if (found) {
        return found
      }
    }

    return null
  }
}

export type NamedEntity = { id: string; name: string }

export interface FacetDistribution {
  name: string
  distribution: NamedEntity[]
}

export type FacetFilterType = "pill" | "checkbox" | "select"
