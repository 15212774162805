export function sortBy<T>(by: keyof T, order: "asc" | "desc" = "asc") {
  const greaterThanNumber = order === "asc" ? 1 : -1
  return (a: T, b: T) =>
    a[by] > b[by] ? greaterThanNumber : -greaterThanNumber
}

// Randomly shuffle the elements in an array
export function shuffleArray<T>(array: T[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

export function sortIds<T>(by: keyof T) {
  return function (a: T, b: T) {
    const listA = String(a[by])
      .split(".")
      .map(str => parseInt(str))
    const listB = String(b[by])
      .split(".")
      .map(str => parseInt(str))
    for (let i = 0; i < Math.max(listA.length, listB.length); i++) {
      const currentA = listA[i] || 0
      const currentB = listB[i] || 0
      if (currentA > currentB) return 1
      if (currentA < currentB) return -1
    }
    return 0
  }
}

export function countByReducer<T>(by: keyof T) {
  return (previousValue: Record<string, number>, currentValue: T) => {
    const currentValueCountedByValue = (currentValue[by] as any) as string
    return {
      ...previousValue,
      [currentValueCountedByValue]:
        (previousValue[currentValueCountedByValue] ?? 0) + 1,
    }
  }
}

export function uniqueByValueReducer<T>(
  accumulatedValues: T[],
  currentValue: T
) {
  if (accumulatedValues.includes(currentValue)) {
    return accumulatedValues
  } else return [...accumulatedValues, currentValue]
}


export function translateCountryReducer<T>(accumulatedValues: T[], currentValue: T) {
  let countryName = '';
  for (let index in contriesArray) {
    let country = contriesArray[index];
    if (country.id === currentValue) {
      countryName = country.name;
      break;
    }
  };
  if (countryName === '') {
    return accumulatedValues
  } else return [...accumulatedValues, countryName]
}

// TODO should be retrieved from Airtable
// HeadQuarterFRCountryName has been added in Airtable
// to delete when filter will be implemented directly with melisearch
export const contriesArray = [
  {
    id: "recoLsvEcgkXugKXb",
    name: "Samoa américaines"
  },
  {
    id: "recjayV9uomRvJrBq",
    name: "Argentine"
  },
  {
    id: "reccZC9gyp01kHxmx",
    name: "Antarctique"
  },
  {
    id: "recwEYvHL4l9vSHAH",
    name: "Angola"
  },
  {
    id: "recP7Bz6Qri7kSrW3",
    name: "Antilles néerlandaises"
  },
  {
    id: "recoQM2qrCtbU7nzA",
    name: "Arménie"
  },
  {
    id: "recblk2GQpHFEJmat",
    name: "Albanie"
  },
  {
    id: "recZwW9oP7F9BUlBh",
    name: "Anguilla"
  },
  {
    id: "recJqWi6CIMTjEHme",
    name: "Antigua et Barbuda"
  },
  {
    id: "recbd0XeN59niDWSK",
    name: "Afghanistan"
  },
  {
    id: "recFDc6UNb2i4v3bw",
    name: "Emirats arabes unis"
  },
  {
    id: "recWoCS6VrDdfSkTe",
    name: "Andorre"
  },
  {
    id: "recr0XjFmePjhgy9Y",
    name: "France"
  },
  {
    id: "recHqUajT9PFefIbK",
    name: "Barbade"
  },
  {
    id: "recLruY4ngJkiFNSl",
    name: "Bosnie-Herzégovine"
  },
  {
    id: "recs8tOsQf7rmXnjW",
    name: "Azerbaïdjan"
  },
  {
    id: "recublU3Jx9NVid34",
    name: "Aruba"
  },
  {
    id: "recmxprJNpFXM8qoU",
    name: "Australie"
  },
  {
    id: "recHgeiYHLjWMNJp7",
    name: "Autriche"
  },
  {
    id: "rec5SGzIf3r0igG3K",
    name: "Bangladesh"
  },
  {
    id: "rec7RMAaOgNJDk4l2",
    name: "Belgique"
  },
  {
    id: "rec6psMmsxzRF96KB",
    name: "Burkina Faso"
  },
  {
    id: "recqFNnUipkHfPkln",
    name: "Bulgarie"
  },
  {
    id: "recSgRUYM66il9INY",
    name: "Bahrein"
  },
  {
    id: "recBnELrVS9yCaCci",
    name: "Burundi"
  },
  {
    id: "rec0jTE4IeZlzVqSm",
    name: "Bénin"
  },
  {
    id: "recaye2JZxIkiYLnS",
    name: "Saint-Barthélemy"
  },
  {
    id: "recjpEtTrmgs0bnxv",
    name: "Bermudes"
  },
  {
    id: "recdpEoE5dtza00PQ",
    name: "Brunéi Darussalam"
  },
  {
    id: "recPNEWPdFeYg7QLz",
    name: "Bolivie (État plurinational de)"
  },
  {
    id: "recX1btVs4hg9BOem",
    name: "Brésil"
  },
  {
    id: "recmfmiLNfZji208c",
    name: "Bahamas"
  },
  {
    id: "recNGmkeFxjrBNmf4",
    name: "Bhoutan"
  },
  {
    id: "recqdoMYuetFXGFK4",
    name: "Botswana"
  },
  {
    id: "recwHXq4Vi04p1Euw",
    name: "Bélarus"
  },
  {
    id: "recJNkr1zrcZk4lyL",
    name: "Bélize"
  },
  {
    id: "rec31hDFKZ9OO9DGr",
    name: "Canada"
  },
  {
    id: "reckg7W7wpmmNqbD0",
    name: "Cocos/Keeling (Îles)"
  },
  {
    id: "recSkCtsWcAIh1zsW",
    name: "Congo, République démocratique du"
  },
  {
    id: "rec7Qzyvxdte9dgGJ",
    name: "République centrafricaine"
  },
  {
    id: "recTyHgEmXJGqIRyf",
    name: "Congo"
  },
  {
    id: "recRwjJJquibWYUhT",
    name: "Suisse"
  },
  {
    id: "recS0I1FAKTiUYSPK",
    name: "Côte d'Ivoire"
  },
  {
    id: "reclWfBfsAdukFs5q",
    name: "Cook, Iles"
  },
  {
    id: "recFEWU2JvSetInJi",
    name: "Chili"
  },
  {
    id: "recWBshf2EhjBNVmC",
    name: "Cameroun"
  },
  {
    id: "recTx25j4lKBrWkc1",
    name: "Chine"
  },
  {
    id: "recjm97BPjMQAA2xs",
    name: "Colombie"
  },
  {
    id: "recVzyzbenkYIsvkR",
    name: "Costa Rica"
  },
  {
    id: "recgGFue34UlYBX1g",
    name: "Cuba"
  },
  {
    id: "reczlAitPkNnAm7HW",
    name: "Cabo Verde"
  },
  {
    id: "recT8oXZDKrI8EKmX",
    name: "Curaçao"
  },
  {
    id: "recpOvcgjctueQUy4",
    name: "Christmas, île"
  },
  {
    id: "recrgHnd4hgK0ii5Z",
    name: "Chypre"
  },
  {
    id: "recSGApuht3ueJqEd",
    name: "Tchèque, République"
  },
  {
    id: "recrN9T8IG8rEFPfJ",
    name: "Allemagne"
  },
  {
    id: "rectuW5cOdEcHuWli",
    name: "Djibouti"
  },
  {
    id: "rect4odQsh0llPbrs",
    name: "Danemark"
  },
  {
    id: "recu0IjOHPbDsUeyY",
    name: "Dominique"
  },
  {
    id: "recUJRSYh9XRlSCXt",
    name: "Dominicaine, République"
  },
  {
    id: "recpLdDU19svAKg2K",
    name: "Algérie"
  },
  {
    id: "recVoQLERcoUa7EHr",
    name: "Equateur"
  },
  {
    id: "recak0L4rweh3XEDb",
    name: "Estonie"
  },
  {
    id: "recB99CyXZVOhMY3T",
    name: "Egypte"
  },
  {
    id: "rec539wyxpTCZwe0F",
    name: "Sahara occidental"
  },
  {
    id: "recLGCILfXG0Va9Gg",
    name: "Erythrée"
  },
  {
    id: "rec57Q3b1ctTmlsQX",
    name: "Espagne"
  },
  {
    id: "recB7GO9hNNpd0v2a",
    name: "Ethiopie"
  },
  {
    id: "recgnVSHlMOI0pgtE",
    name: "Finlande"
  },
  {
    id: "recr8nwXBujEv4CaU",
    name: "Fidji"
  },
  {
    id: "recmBKVBkzYkJ2IwF",
    name: "Falkland/Malouines (Îles)"
  },
  {
    id: "rec2r2YL9FgGnyUhf",
    name: "Micronésie, Etats Fédérés de"
  },
  {
    id: "recEFVwegh9HZBZ1x",
    name: "Féroé, îles"
  },
  {
    id: "recgELirMK9UlfimT",
    name: "Gabon"
  },
  {
    id: "recu5AsK96102J0WN",
    name: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord"
  },
  {
    id: "rec3andQ5Irqckpul",
    name: "Grenade"
  },
  {
    id: "recZ7x0zt4JxZb6Zn",
    name: "Géorgie"
  },
  {
    id: "recdQSiHcHpu4gGd2",
    name: "Guernesey"
  },
  {
    id: "recmFkBQlfTKUC89O",
    name: "Ghana"
  },
  {
    id: "recm3h2BS0LlqQnVX",
    name: "Gibraltar"
  },
  {
    id: "rec5yb59GxKMlzi5c",
    name: "Groenland"
  },
  {
    id: "reczxZTn2d64LBKHp",
    name: "Gambie"
  },
  {
    id: "recugGsIU8ag1OR1U",
    name: "Guinée"
  },
  {
    id: "reczBUGGfj5PQz3oX",
    name: "Guinée équatoriale"
  },
  {
    id: "reczpwj0V8dJb0cK3",
    name: "Grèce"
  },
  {
    id: "recn4142rrBrvL3rw",
    name: "Guatemala"
  },
  {
    id: "reck4HhNjfJGIn0O0",
    name: "Guam"
  },
  {
    id: "recUI1SPMvxjLIQhg",
    name: "Guinée-Bissau"
  },
  {
    id: "recrSO8xGHlWGGUKU",
    name: "Guyana"
  },
  {
    id: "recOOopWeK7i7zb0W",
    name: "Hong Kong"
  },
  {
    id: "recseqbstldShYJX0",
    name: "Honduras"
  },
  {
    id: "recykBitnXL1tnHkp",
    name: "Croatie"
  },
  {
    id: "recZPk39alcuMWZ3H",
    name: "Haïti"
  },
  {
    id: "recCH6DVg8rJXXQM3",
    name: "Hongrie"
  },
  {
    id: "recpYNJg0gJSyBQQq",
    name: "Indonésie"
  },
  {
    id: "recwdDbsBV6kOcZtk",
    name: "Irlande"
  },
  {
    id: "recTSFlFJ5dzk9Jgz",
    name: "Israël"
  },
  {
    id: "recgNQKN7UrFHrde0",
    name: "Île de Man"
  },
  {
    id: "rec78qY3aJw38QFrM",
    name: "Inde"
  },
  {
    id: "recWPSvzpJNFWWte5",
    name: "Indien (Territoire britannique de l'océan)"
  },
  {
    id: "recvgUvF8miE7kYKL",
    name: "Iraq"
  },
  {
    id: "rec2xRXCxgdnsEz77",
    name: "Iran, République islamique d'"
  },
  {
    id: "recu7zZ7XHTxF3NqH",
    name: "Islande"
  },
  {
    id: "recA9DHf0IsP1Tc7y",
    name: "Italie"
  },
  {
    id: "recrymf8WZRko3VSr",
    name: "Jersey"
  },
  {
    id: "recsi6pylg7CwWImZ",
    name: "Jamaïque"
  },
  {
    id: "recfgjR2mTCjJT1Fo",
    name: "Jordanie"
  },
  {
    id: "recIUC7QelKyIr29T",
    name: "Japon"
  },
  {
    id: "rec1onsb2AfawQ0z0",
    name: "Kenya"
  },
  {
    id: "rec321fQ4ogCJOFJv",
    name: "Kirghizistan"
  },
  {
    id: "recJwIK2F3qcYWBaj",
    name: "Cambodge"
  },
  {
    id: "recfpn0wXb0dIVH9U",
    name: "Kiribati"
  },
  {
    id: "recnZB1s7F9HM39fx",
    name: "Comores"
  },
  {
    id: "recHwi7SiWKF4PmcO",
    name: "Saint-Kitts-et-Nevis"
  },
  {
    id: "recAT3XKlQYOk6Yis",
    name: "Corée, République populaire démocratique de"
  },
  {
    id: "reciY1EZbOF0jfeiI",
    name: "Corée, République de"
  },
  {
    id: "recDvqdThtQyAtIUH",
    name: "Koweït"
  },
  {
    id: "recs4DyCIPtyF7z3U",
    name: "Caïmans, Iles"
  },
  {
    id: "recNKeExTZXRmZPUe",
    name: "Kazakhstan"
  },
  {
    id: "recgskariy8wPFf9c",
    name: "Lao, République démocratique populaire"
  },
  {
    id: "recmOnYbd47d3KWRs",
    name: "Liban"
  },
  {
    id: "recFqcBWp3C0BvuVW",
    name: "Sainte-Lucie"
  },
  {
    id: "recRC3kBk9pwAzhDD",
    name: "Liechtenstein"
  },
  {
    id: "recb8caBvfJEvvtfz",
    name: "Sri Lanka"
  },
  {
    id: "recvMznVlCp6ak62k",
    name: "Libéria"
  },
  {
    id: "recXHTRvhGOcyy0g6",
    name: "Lesotho"
  },
  {
    id: "recnp59QD7u5DE4qV",
    name: "Lituanie"
  },
  {
    id: "receVnhO46R0EwWES",
    name: "Luxembourg"
  },
  {
    id: "recR26cGJbQIPxUBm",
    name: "Lettonie"
  },
  {
    id: "recjl80DYaHMAyCuQ",
    name: "Libye"
  },
  {
    id: "recGeHuPVhqyQ16pE",
    name: "Maroc"
  },
  {
    id: "recY3aPHChkbjFRow",
    name: "Monaco"
  },
  {
    id: "reck0ZdYCeROFAVA8",
    name: "Moldova, République de"
  },
  {
    id: "recj0fzL70GUDmI1t",
    name: "Monténégro"
  },
  {
    id: "recQnyxqHdtPDjbHt",
    name: "Saint-Martin (partie française)"
  },
  {
    id: "recMKqvfxlvtvSlEt",
    name: "Madagascar"
  },
  {
    id: "recQig1dopKgNUlWg",
    name: "Marshall, Iles"
  },
  {
    id: "rec2t18vg5Oe8dsyQ",
    name: "Macédoine, l'ex-République yougoslave de"
  },
  {
    id: "rec9hYzGFxD0YDlB9",
    name: "Mali"
  },
  {
    id: "recUo8bGSyFtIkTfR",
    name: "Myanmar"
  },
  {
    id: "recgr8gK8oktePR4y",
    name: "Mongolie"
  },
  {
    id: "recoIRW8yZraKpcm7",
    name: "Macao"
  },
  {
    id: "recHKJLD7r0lvnVI8",
    name: "Mariannes du nord, Iles"
  },
  {
    id: "recrXnhLBrIIACUV7",
    name: "Mauritanie"
  },
  {
    id: "rec4x8N4IBSRTIVbx",
    name: "Montserrat"
  },
  {
    id: "rec5XMWAfhKvfZaGK",
    name: "Malte"
  },
  {
    id: "recyPeBnoom8JdFuT",
    name: "Maurice"
  },
  {
    id: "recIobZdo2fqUCFQR",
    name: "Maldives"
  },
  {
    id: "recNDxgDGCbyHWiVV",
    name: "Malawi"
  },
  {
    id: "recBn5AcPZjPG6lY4",
    name: "Mexique"
  },
  {
    id: "receBkUpFSRFwDCFs",
    name: "Malaisie"
  },
  {
    id: "recilXbaTLQPcLdaz",
    name: "Mozambique"
  },
  {
    id: "recKZxEw6kuzEELfE",
    name: "Namibie"
  },
  {
    id: "reccGcHe9TDFmsPTU",
    name: "Nouvelle-Calédonie"
  },
  {
    id: "recF81kXZ1m1OxViY",
    name: "Niger"
  },
  {
    id: "recDAs6RCEeNb7ub7",
    name: "Nigéria"
  },
  {
    id: "reccKIx8NRgrxNrw1",
    name: "Nicaragua"
  },
  {
    id: "rect7ozw2MZcH3f8E",
    name: "Pays-Bas"
  },
  {
    id: "recQLYEPCPW5RTft5",
    name: "Norvège"
  },
  {
    id: "recQ3Q5X3kaPDBlCQ",
    name: "Népal"
  },
  {
    id: "recyZj0Yfwap1ybqy",
    name: "Nauru"
  },
  {
    id: "recV5gZELFFXB7H63",
    name: "Niue"
  },
  {
    id: "reckCRzMtsTUjhxza",
    name: "Nouvelle-Zélande"
  },
  {
    id: "recAWUxDPLQPOi0OB",
    name: "Oman"
  },
  {
    id: "recdpdWbRmUW0m4Hb",
    name: "Panama"
  },
  {
    id: "recxuBv4MEEagyGWG",
    name: "Pérou"
  },
  {
    id: "recqYAdC2pBahAW4O",
    name: "Polynésie française"
  },
  {
    id: "recqAgqi2fuAndfra",
    name: "Papouasie-Nouvelle-Guinée"
  },
  {
    id: "recz2ehsmZ6RTvsvc",
    name: "Philippines"
  },
  {
    id: "recp0bWQTmW5rTCxI",
    name: "Pakistan"
  },
  {
    id: "recRoBOQskCnhuzik",
    name: "Pologne"
  },
  {
    id: "recP9uBsR7sLbt8ha",
    name: "Saint-Pierre-et-Miquelon"
  },
  {
    id: "recdwI7SZ0rqVZVtL",
    name: "Pitcairn"
  },
  {
    id: "rece2YDqH9sKWfxOG",
    name: "Porto Rico"
  },
  {
    id: "rec6EYVdP6LrawvxO",
    name: "Palestine, Etat de"
  },
  {
    id: "recVlpDcKirunU703",
    name: "Portugal"
  },
  {
    id: "reckDXHBqV10aNvze",
    name: "Palaos"
  },
  {
    id: "rec6Esz7XZWtJBJVm",
    name: "Paraguay"
  },
  {
    id: "recUdO0mwXDuss3tc",
    name: "Qatar"
  },
  {
    id: "recfhBPUqA2YMNGSL",
    name: "Réunion"
  },
  {
    id: "recr64XrdPPfpHU2O",
    name: "Roumanie"
  },
  {
    id: "recKKNQHdqlnNeidz",
    name: "Serbie"
  },
  {
    id: "recqXuuOPKmC8rulE",
    name: "Russie, Fédération de"
  },
  {
    id: "recdrbxDgsO4igpi6",
    name: "Rwanda"
  },
  {
    id: "recgrp38df8oBoPJ1",
    name: "Arabie Saoudite"
  },
  {
    id: "recDnlwnJqalSwQ3u",
    name: "Salomon, Iles"
  },
  {
    id: "rec4Eglc7Xzp60YtZ",
    name: "Seychelles"
  },
  {
    id: "recL34wtE2V6tUKVc",
    name: "Soudan"
  },
  {
    id: "recfRfqnBvi9Q6v47",
    name: "Suède"
  },
  {
    id: "recjvtOX8xAwSpOJS",
    name: "Singapour"
  },
  {
    id: "recZHUcbu8A03w1qw",
    name: "Sainte-Hélène, Ascension et Tristan da Cunha"
  },
  {
    id: "recaopBY7kky9VK06",
    name: "Slovénie"
  },
  {
    id: "rec7NQmWPNZtZSsZ0",
    name: "Svalbard et île Jan Mayen"
  },
  {
    id: "recXPCq71aeozPfyN",
    name: "Slovaquie"
  },
  {
    id: "recEvsqaw4SBk0VtW",
    name: "Sierra Leone"
  },
  {
    id: "recqX2xmhxxThQcYL",
    name: "Saint-Marin"
  },
  {
    id: "recCD74qFHXQiTvdR",
    name: "Sénégal"
  },
  {
    id: "recP7AIWj97FQzKyi",
    name: "Somalie"
  },
  {
    id: "recgBUxMbyeD9oAkJ",
    name: "Suriname"
  },
  {
    id: "recDRtIiuLkWbfBCK",
    name: "Soudan du Sud"
  },
  {
    id: "recZcKsOq0oTJFXEd",
    name: "Sao Tomé-et-Principe"
  },
  {
    id: "rec2kv83P0oG6ySAJ",
    name: "El Salvador"
  },
  {
    id: "reckGF4wqL831eRld",
    name: "Saint-Martin (partie néerlandaise)"
  },
  {
    id: "recm59ma1sRmybjYM",
    name: "République arabe syrienne"
  },
  {
    id: "recPeFcLPxzKj68Mk",
    name: "Swaziland"
  },
  {
    id: "recaqXOdffW89sJKk",
    name: "Turks-et-Caïcos (Îles)"
  },
  {
    id: "rec8jukXOVOttIqks",
    name: "Tchad"
  },
  {
    id: "recMlLGp7gcvhI3q0",
    name: "Togo"
  },
  {
    id: "rechFmuwzre3VXG2W",
    name: "Thaïlande"
  },
  {
    id: "recn5UqLtsqzoTvMr",
    name: "Tadjikistan"
  },
  {
    id: "recsYohHDXvCPb5Tm",
    name: "Tokelau"
  },
  {
    id: "recJc4QLzBHewZTiM",
    name: "Timor-Leste"
  },
  {
    id: "rec8HwC8SdzxyPTA3",
    name: "Turkménistan"
  },
  {
    id: "rec3IXvAOcfIGQZm8",
    name: "Tunisie"
  },
  {
    id: "rechavdLjPc0VuSYO",
    name: "Tonga"
  },
  {
    id: "recosg82Wj3BNgREz",
    name: "Turquie"
  },
  {
    id: "recYPB2L7pgHT9bfC",
    name: "Trinité-et-Tobago"
  },
  {
    id: "recAGMIznqDusRwRv",
    name: "Tuvalu"
  },
  {
    id: "recfYn9SUsoigWyDg",
    name: "Taïwan, Province de Chine"
  },
  {
    id: "receul3DRvUCPaPtq",
    name: "Tanzanie, République unie de"
  },
  {
    id: "reccpONeTiveKWWWr",
    name: "Ukraine"
  },
  {
    id: "recmRBkCQtqgg14qy",
    name: "Ouganda"
  },
  {
    id: "recRVglnDHxEkVeQa",
    name: "Etats-Unis d'Amérique"
  },
  {
    id: "recG99zi2WB0B3JqC",
    name: "Uruguay"
  },
  {
    id: "rec0plOM5k1l8I2Wi",
    name: "Ouzbékistan"
  },
  {
    id: "rec7B7lNl1Qhu8xCQ",
    name: "Saint-Siège"
  },
  {
    id: "recFN3WqSTPwBlqFR",
    name: "Saint-Vincent-et-les-Grenadines"
  },
  {
    id: "recotcGNj2XVoXbqg",
    name: "Venezuela (République bolivarienne du)"
  },
  {
    id: "rechmeu4zvBQzWvnh",
    name: "Îles vierges britanniques"
  },
  {
    id: "recYtKq8S8Xo0YMNk",
    name: "Îles vierges des Etats-Unis"
  },
  {
    id: "recTPKyquQE3RREYQ",
    name: "Viet Nam"
  },
  {
    id: "reccPNQaBrXKu3vmL",
    name: "Vanuatu"
  },
  {
    id: "recKo40jMZNMTGG0b",
    name: "Wallis et Futuna"
  },
  {
    id: "recKqRJGhuyLbN0PI",
    name: "Samoa"
  },
  {
    id: "recLHDqWy8PHKdieH",
    name: "#N/A"
  },
  {
    id: "rectNJEKaqKQVjZ7W",
    name: "Yémen"
  },
  {
    id: "recIh7mEcy0idp5Ph",
    name: "Mayotte"
  },
  {
    id: "recwwJ2hfXbeDsoMX",
    name: "Afrique du Sud"
  },
  {
    id: "recoQvdxxnU7i0rSl",
    name: "Zambie"
  },
  {
    id: "rec80Jk3VU4qstKh8",
    name: "Zimbabwe"
  }
];

