import { Category } from "./category"
import { Coordinates } from "./coordinates"
import { Lever } from "./lever"

const slug = require("slug")

interface OrganizationConstructorParams {
  id: string
  name: string
  desc?: string
  structure?: string
  effectif?: string
  categories?: Category[]
  creationYear?: number
  officeCities?: string[]
  officeCoordinates?: Coordinates[]
  headquarter?: string
  headquarterCoordinates?: Coordinates
  fullLocations?: string[]
  fullCoordinates?: Coordinates[]
  website?: string
  linkedin?: string
  tags?: string[]
  levers?: Lever[]
  trainings?: string[]
  professions?: string[]
  departments?: string[]
  regions?: string[]
  countries?: string[]
  idMakeSense?: string
}

export class Organization {
  id: string
  name: string
  categories: Category[]
  desc: string
  structure: string
  effectif: string
  creationYear: number
  logo: {
    ext: string
    publicURL: string
    childImageSharp: { fluid: any }
  }
  officeCities: string[]
  officeCoordinates: Coordinates[]
  headquarter: string
  headquarterCoordinates: Coordinates
  fullLocations: string[]
  fullCoordinates: Coordinates[]
  linkedin: URL
  website: URL
  tags: string[]
  levers: Array<Lever>
  trainings: string[]
  professions: string[]
  departments: string[]
  regions: string[]
  countries: string[]
  idMakeSense: string

  constructor({
    id,
    name,
    desc = "",
    structure = "",
    effectif = "",
    categories = [],
    creationYear = null,
    officeCities = [],
    website = null,
    linkedin = null,
    tags = [],
    levers = [],
    officeCoordinates = [],
    fullCoordinates = [],
    fullLocations = [],
    headquarter,
    headquarterCoordinates = { latitude: 0, longitude: 0 },
    trainings = [],
    professions = [],
    departments = [],
    regions = [],
    countries = [],
    idMakeSense = null,
  }: OrganizationConstructorParams) {
    this.id = id
    this.name = name
    this.desc = desc
    this.structure = structure
    this.effectif = effectif?.substring(2)
    this.categories = categories
    this.creationYear = creationYear
    this.officeCities = officeCities
    this.officeCoordinates = officeCoordinates
    this.headquarter = headquarter
    this.headquarterCoordinates = headquarterCoordinates
    this.fullCoordinates = fullCoordinates
    this.fullLocations = fullLocations
    this.linkedin = this.parseUrl(linkedin)
    this.website = this.parseUrl(website)
    this.tags = tags
    this.levers = levers
    this.trainings = trainings
    this.professions = professions
    this.departments = departments
    this.regions = regions
    this.countries = countries
    this.idMakeSense = idMakeSense
  }

  get slug(): string {
    return `/organizations/${slug(this.name)}`
  }

  parseUrl(raw: string): URL {
    if (raw) {
      try {
        if (raw.startsWith("http")) {
          return new URL(raw)
        } else {
          return new URL("http://" + raw)
        }
      } catch (error) {
        console.log("Invalid URL", raw)
      }
    }
    return null
  }
}
