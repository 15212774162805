import { FacetFilters } from "./facetfilters"
import { Organization } from "./organization"
const slug = require("slug")

export const levelMap = [
  "root",
  "secteur",
  "sous-secteur",
  "famille",
  "sous-famille",
]

export class Category {
  /*
    Highlights
    CategoryPicto
    */
  parent: Category
  id: string
  originalName: string
  SEOTitle: string
  SEODesc: string
  SEOSearchDesc: string
  desc: string
  keyFigures: string
  contributionType: string
  children: Array<Category>
  organizations: Array<Organization>
  newOrgCount: number
  level: number
  hasDedicatedPage: boolean
  airtableId: string
  private _pictoURL: string
  private _totalOrganizations: Array<Organization>
  private _totalOrganizationsCached: boolean = false

  constructor(
    parent: Category,
    id: string,
    name: string,
    desc: string,
    SEOTitle: string,
    SEODesc: string,
    SEOSearchDesc: string,
    keyFigures: string,
    contributionType: string,
    pictoURL: string,
    newOrgCount: number,
    level: number = 0,
    hasDedicatedPage: boolean = false,
    airtableId: string = "",
    organizations = []
  ) {
    this.parent = parent
    this.id = id
    this.SEOTitle = SEOTitle
    this.SEODesc = SEODesc
    this.SEOSearchDesc = SEOSearchDesc
    this.originalName = name
    this.desc = desc
    this.contributionType = contributionType
    this.newOrgCount = newOrgCount
    this.level = level
    this.keyFigures = keyFigures
    this._pictoURL = pictoURL
    this.hasDedicatedPage = hasDedicatedPage
    this.airtableId = airtableId
    this.organizations = organizations
  }

  get name(): string {
    return this.originalName
  }

  get fullname(): string {
    return this.id + "#" + this.originalName
  }

  get nameSlug(): string {
    return slug(this.name)
  }

  get slug(): string {
    if (this.hasDedicatedPage) {
      return this.parent
        ? `${this.parent.slug}/${this.nameSlug}`
        : "/" + this.nameSlug
    } else {
      return this.parent ? this.parent.slug : "/" + this.nameSlug
    }
  }

  get searchSlug(): string {
    if (!this.parent) {
      return "/search"
    }
    if (this.ancestors().length <= 2) {
      return `${this.parent.searchSlug}/${this.nameSlug}`
    }
  }

  get pictoURL(): string {
    return this._pictoURL
      ? this._pictoURL
      : this.parent
      ? this.parent.pictoURL
      : ""
  }

  ancestors(): Array<Category> {
    return this.parent ? [this.parent, ...this.parent?.ancestors()] : []
  }

  childrenIds(): Array<string> {
    let ids = [this.id]

    for (let child of this.children) {
      ids.push(...child.childrenIds())
    }

    return ids
  }

  siblings(): Array<Category> {
    return this.parent ? this.parent.children : []
  }
  
  get totalOrganizations(): Array<Organization> {
    if (!this._totalOrganizationsCached) {
      let orgs = this.organizations

      for (let child of this.children) {
        orgs = [...orgs, ...child.totalOrganizations]
      }
      this._totalOrganizationsCached = true
      this._totalOrganizations = orgs
    }
    return this._totalOrganizations
  }

  filters(): FacetFilters {
    let filters = new FacetFilters()
    filters.add(levelMap[this.level], this.fullname)
    let current = this.parent

    while (current.level > 0) {
      filters.add(levelMap[current.level], current.fullname)
      current = current.parent
    }

    return filters
  }
}
